<template>
  <div class="main">
    <img src="@/assets/login.png" alt="" class="login-image" />
    <div class="login-box">
      <div class="login-box-inner">
        <h2>申请账号</h2>
        <el-form :model="form" :rules="rules" ref="form">
          <el-form-item prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入公司名称/个人名称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="address">
            <el-input
              v-model="form.address"
              placeholder="请输入公司/个人所在地"
            ></el-input>
          </el-form-item>
          <el-form-item prop="contact">
            <el-input
              v-model="form.contact"
              placeholder="请输入联系人名称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="mobile">
            <el-input
              v-model="form.mobile"
              placeholder="请输入联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item prop="qq">
            <el-input
              v-model="form.qq"
              placeholder="请输入QQ号（选填）"
            ></el-input>
          </el-form-item>
          <el-form-item prop="email">
            <el-input
              v-model="form.email"
              placeholder="请输入邮箱（选填）"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              class="login-btn"
              native-type="submit"
              @click.prevent="submit"
              :loading="loading"
              >申请</el-button
            >
          </el-form-item>
          <div>
            已有账号？<el-link
              type="primary"
              @click="
                () => {
                  $router.push('/login')
                }
              "
              >去登录</el-link
            >
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      form: {
        name: '',
        address: '',
        contact: '',
        mobile: '',
        qq: '',
        email: ''
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入公司名称/个人名称',
            trigger: 'blur'
          }
        ],
        address: [
          {
            required: true,
            message: '请输入公司/个人所在地',
            trigger: 'blur'
          }
        ],
        contact: [
          {
            required: true,
            message: '请输入联系人名称',
            trigger: 'blur'
          }
        ],
        mobile: [
          {
            required: true,
            message: '请输入联系方式',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    submit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          this.$request({
            url: '/register',
            method: 'post',
            data: {
              companyName: this.form.name,
              companyAddress: this.form.address,
              contactName: this.form.contact,
              //  personalName:this.form.mobile,
              // personalAddress:this.form.,
              mobile: this.form.mobile,
              qQNumber: this.form.qq,
              email: this.form.email
            }
          }).then((ret) => {
            console.log(ret)
            this.$alert('申请信息提交成功，我们核对后尽快联系您', '提示', {
              confirmButtonText: '确定',
              callback: () => {
                this.$router.push('/login')
              }
            })
          })
          // window.localStorage.setItem('signed', 1)
          // window.location.replace('/')
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // height: 100%;
  // width: 100vh;
  display: flex;
  > .login-image {
    width: 50%;
    height: 100%;
  }
  > .login-box {
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    > .login-box-inner {
      width: 50%;
      max-width: 400px;
      > h2 {
        text-align: center;
        color: rgba(24, 59, 86, 1);
      }
      .login-btn {
        width: 100%;
      }
    }
  }
}
</style>